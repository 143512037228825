.linkPortal {
  min-height: 100vh;
  width: 100%;
  background-color: #3e3d40;
  display: flex;
  align-items: center;
  justify-content: center;

  .connectAppsCard {
    border: 1px solid rgba(173, 174, 182, 0.5);
    border-radius: 8px;
    max-height: 408px;
    padding: 26px 0;
    box-sizing: border-box;
    background-color: #3e3d40;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 6px;

    .cardTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      margin: 0;
      padding: 0 26px;
    }
    .cardSubtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
      padding: 0 26px;
      margin: 0 0 26px 0;
      opacity: 0.5;
      span {
        font-weight: 600;
      }
    }

    .scrollableContainer {
      overflow-y: auto;
      height: calc(100% - 70px);
      width: 100%;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #adaeb6;
      }
      &::-webkit-scrollbar-thumb {
        background: #575759;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #575759;
        transform: scaleX(0.9);
      }
      .loadingContainer {
        height: 280px;
        min-width: 452px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .buttonsContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0 20px 0 26px;
        width: 100%;
        .button {
          padding: 12px 12px 12px 24px;
          box-sizing: border-box;
          min-width: 400px;
          width: 100%;
          border-radius: 8px;
          height: 72px;
          background: #575759;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          .left {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .icon {
              height: 40px;
              width: 40px;
            }
            .appName {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              margin-left: 16px;
              font-family: "Poppins";
              font-style: normal;
            }
          }
          .buttonPreview {
            width: 150px;
            cursor: pointer;
            background: #81b9f9;
            border-radius: 8px;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding: 12px 23px;

            .buttonIcon {
              margin-left: 8px;
            }
          }
          .disconnectButton {
            background-color: #fd6f61;
            border-radius: 8px;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding: 12px 23px;
          }
          .loading {
            opacity: 0.8;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .absoluteContainer {
    position: absolute;
    top: 100px;
    width: 100%;

    .errorContainer {
      margin: 0 60px;
      height: 40px;
      background: #fd7062;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      .icon {
        margin-right: 12px;
      }
    }
  }

  .mainArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .whiteBackground {
      width: 60px;
      height: 60px;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        height: 40px;
        width: 40px;
      }
    }
    .appName {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      padding: 0;
      margin: 20px 0;
    }
    .subtitleText {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #adaeb6;
      max-width: 500px;
      text-align: center;
      margin-bottom: 40px;
      padding: 0;
      height: 50px;
    }

    .integrateButton {
      border: none;
      outline: none;
      padding: 10px 16px;
      border-radius: 8px;

      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #424451;
      cursor: pointer;
      font-family: "Poppins";
    }
    .disconnectButton {
      border: none;
      outline: none;
      padding: 10px 16px;
      border-radius: 8px;

      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      background-color: #fd6f61;
      color: #ffffff;
      cursor: pointer;
      font-family: "Poppins";
    }
    .loading {
      opacity: 0.8;
      cursor: not-allowed;
    }
    .poweredByText {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      color: #ffffff;

      opacity: 0.5;
    }
  }
}
