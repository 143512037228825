.footer {
  max-height: 70px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 7;
  background: #3e3d40;
  display: flex;
  align-items: center;
  padding: 0 40px;
  justify-content: center;
  box-sizing: border-box;

  .footerText {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #adaeb6;
    opacity: 0.5;
  }
}
