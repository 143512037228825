.navbar {
  max-height: 70px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 7;
  background: #2d2d31;
  display: flex;
  align-items: center;
  padding: 0 60px;
  justify-content: space-between;
  box-sizing: border-box;

  .logoArea {
    display: flex;
    align-items: center;
    .logoContainer {
      border-radius: 16px;
      height: 70px;
      display: flex;
      align-items: center;

      .logo {
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }
      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        transition: 0.2s all linear;
      }
    }
    .verticalSeparator {
      width: 3px;
      height: 20px;
      background-color: #fff;
      border-radius: 4px;
      margin: 0 10px;
    }
    .org {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      opacity: 0.5;
    }
  }
}
