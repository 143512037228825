@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.emptyWidget {
  text-align: center;

  .appCard {
    padding: 6px 30px;
    width: 100%;
    height: 100px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    box-sizing: border-box;

    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .whiteBackground {
        width: 60px;
        height: 60px;
        background-color: #fff;
        border-radius: 8px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          height: 40px;
          width: 40px;
        }
      }

      .textArea {
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .titleText {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          font-family: "Poppins";
        }
        .subtitleText {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          font-family: "Poppins";

          opacity: 0.7;
          margin: 0;
          padding: 0;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      .integrateButton {
        border: none;
        outline: none;
        padding: 10px 16px;
        border-radius: 8px;

        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #424451;
        cursor: pointer;
        font-family: "Poppins";
      }
    }
  }
  .shimmer {
    background: linear-gradient(270deg, #b7c0ca, #647a93);
    background-size: 400% 400%;
    -webkit-animation: shimmer 30s ease infinite;
    -moz-animation: shimmer 30s ease infinite;
    animation: shimmer 2s ease infinite;
    opacity: 0.7;
  }

  @-webkit-keyframes shimmer {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes shimmer {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes shimmer {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
